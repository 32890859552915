import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Layout, SEO, Top, Hero, Logotypes, HowItWorks, VideoGallery, Reasons, Experience, Contact, Footer } from './../components'

const IndexPage = ({ data }) => (
  <Layout>
    <StaticQuery
      query={q}
      render={data => (
        <SEO title={data.site.siteMetadata.title} description={data.site.siteMetadata.description} />
      )}
    />
    <Top withoutHero={false} />
    <Hero />
    <Logotypes />
    <HowItWorks />
    <VideoGallery />
    <Reasons />
    <Experience />
    <Contact />
    <Footer />
    <div id="portal"></div>
  </Layout>
)

export default IndexPage

const q = graphql`
query {
  site {
    siteMetadata {
      title
      description
    }
  }
}
`